import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      width: '100%',
      height: _var(CSS_VARIABLES.appHeight),
      display: 'flex',
      fontFamily: _var(CSS_VARIABLES.fontFamily),
      color: '#fff',
    },
    content: {
      height: 'fit-content',
      minHeight: '100%',
      width: '100%',
      background: 'linear-gradient(180deg, #1D1C35 0%, #000 38.67%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      overflow: 'hidden',
    },
  });
}
