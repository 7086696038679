import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { AppBreadcrumbs } from '@shared/components/breadcrumbs';
import { BreadcrumbsLink } from '@shared/types/breadcrumbs-links';

import { styles } from './desktop-content-layout.styles';

export interface PrivateLayoutProps extends AppWithStyles<typeof styles> {
  links: Array<BreadcrumbsLink>;
  children: React.ReactNode;
}

const DesktopContentLayout: React.FC<PrivateLayoutProps> = ({ classes, links, children }) => {
  return (
    <div className={classes.root}>
      <div className={classes.breadcrumbs}>
        <AppBreadcrumbs links={links} />
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default appWithStyles(styles)(DesktopContentLayout);
