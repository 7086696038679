import React, { useEffect } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { OrderDetailsContainer } from '@modules/new-private/orders';
import { Outlet, useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';

import { styles } from './private-layout.styles';

export interface PrivateLayoutProps extends AppWithStyles<typeof styles> {}

const PrivatLayout: React.FC<PrivateLayoutProps> = ({ classes }) => {
  const navigate = useNavigate();
  //const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.mobile));

  useEffect(() => {
    if (location.pathname === ROUTES.mobilePrivate.root) {
      navigate(ROUTES.mobilePrivate.dashboard);
    }
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Outlet />
      </div>
      <OrderDetailsContainer />
    </div>
  );
};

export default appWithStyles(styles)(PrivatLayout);
