import { appCreateStyles } from '@core/theme/utils/create-styles';

export function styles() {
  return appCreateStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    breadcrumbs: {
      position: 'absolute',
      top: '20px',
      left: '24px',
    },
    content: {
      width: 'fit-content',
      height: 'fit-content',
      borderRadius: '10px',
      border: '1px solid #473763',
      background: '#291945',
      position: 'relative',
      '&:has(.invisible-content)': {
        border: 'none',
        background: 'transparent',
      },
    },
  });
}
