import React, { useMemo } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { LineChart } from '@shared/components/line-chart/line-chart';
import { Loading } from '@shared/components/loading';
import { ChartControls } from '@shared/components/new-design/chart-controls/chart-controls';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import { Layout } from '@shared/utils/layout';
import classNames from 'classnames';
import { useQuery } from 'react-query';

import { MobileMarketViewModel } from './mobile-market.vm';

import { styles } from './mobile-market.styles';

export interface MobileActivityProps extends AppWithStyles<typeof styles> {}

const MobileActivity: React.FC<MobileActivityProps> = appObserver(({ classes }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));
  const $vm = useMemo(() => new MobileMarketViewModel(isMobile), []);

  const fetchSpotPriceDataQuery = useQuery(
    ['download-spot-price-data', $vm.currency, $vm.currentPeriod],
    () => $vm.fetchSpotPriceData(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    },
  );

  return (
    <div className={classNames(classes.root, 'invisible-content')}>
      <div className={classes.title}>{`${$vm.currentMetal} ${t`Market`}`}</div>
      {fetchSpotPriceDataQuery.isFetching ? (
        <></>
      ) : (
        <div className={classes.ratesBlock}>
          <ul className={classes.rates}>
            <li>
              <span className={classes.value}>
                {minDigitsAfterDot(amountPipe($vm.getGrRate(), 2))}{' '}
              </span>
              <span>
                {$vm.currency}/{t`gr`}
              </span>
            </li>
            <li>
              <span className={classes.value}>
                {minDigitsAfterDot(amountPipe($vm.getOzRate(), 2))}{' '}
              </span>
              <span>
                {$vm.currency}/{t`oz`}
              </span>
            </li>
            <li>
              <span className={classes.value}>
                {minDigitsAfterDot(amountPipe($vm.getKgRate(), 2))}{' '}
              </span>
              <span>
                {$vm.currency}/{t`kg`}
              </span>
            </li>
          </ul>
        </div>
      )}
      <div className={classes.chartName}>{t`Spot Price Trend`}</div>
      {fetchSpotPriceDataQuery.isFetching ? (
        <></>
      ) : (
        <div className={classes.currentRate}>
          <div className={classes.currentRateTitle}>{t`Current spot price`}:</div>
          <div className={classes.currentRateValue}>
            {minDigitsAfterDot(amountPipe($vm.getGrRate(), 2))} {$vm.currency}/{t`gr`}
          </div>
        </div>
      )}
      <div className={classes.box}>
        {fetchSpotPriceDataQuery.isFetching && (
          <div className={classes.loader}>
            <Loading size={30} />
          </div>
        )}
        {!fetchSpotPriceDataQuery.isFetching && (
          <LineChart options={$vm.chartOptions} data={$vm.chartData} />
        )}
      </div>
      <div className={classes.controls}>
        <ChartControls
          currentPeriod={$vm.currentPeriod}
          periodOptions={$vm.periodOptions}
          updatePeriod={$vm.updatePeriod}
        />
      </div>
    </div>
  );
});

export default appWithStyles(styles)(MobileActivity);
