import { appCreateStyles } from '@core/theme/utils/create-styles';

export function styles() {
  return appCreateStyles({
    content: {
      padding: '24px',
      width: '100%',
      height: '90%',
      overflow: 'hidden',
      borderRadius: '12px',
      position: 'relative',
    },
    invisibleContent: {
      height: '100%',
    },
  });
}
