import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import ContectSupportIcon from '@shared/components/new-design/images/info-icon.svg';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles({ breakpoints }: Theme) {
  return appCreateStyles({
    root: {
      width: '100%',
      height: `calc(${_var(CSS_VARIABLES.appHeight)} - 85px)`,
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'start',
      [breakpoints.up(Layout.laptop)]: {
        padding: '0px',
        height: `100%`,
      },
    },
    title: {
      color: '#FFF',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '0.3px',
      [breakpoints.up(Layout.laptop)]: {
        textAlign: 'left',
      },
    },
    rates: {
      borderRadius: '8px',
      background: '#1E1D36',
      width: '100%',
      height: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      color: 'rgba(255, 255, 255, 0.90)',
      fontSize: '12px',
      fontWeight: 400,

      [breakpoints.up(Layout.laptop)]: {
        background: '#2E1E4A',
        width: '335px',
      },
    },
    ratesBlock: {
      marginTop: '30px',
    },
    value: {
      fontWeight: 600,
    },
    container: {
      width: '100%',
      marginTop: '32px',
      padding: '0px 14px',
    },
    chartHeader: {
      display: 'flex',
    },
    h2: {
      color: '#79818F',
      fontSize: '14px',
      fontWeight: 500,
    },
    controls: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '32px',
      [breakpoints.up(Layout.laptop)]: {
        top: '-5px',
        right: '20px',
        position: 'absolute',
      },
    },
    box: {
      height: '100%',
      width: '100%',
      [breakpoints.up(Layout.laptop)]: {
        width: '97%',
      },
      '& .line-chart-view': {
        minHeight: '100%',
        minWidth: '112%',
        marginLeft: '-8%',
        [breakpoints.up(Layout.laptop)]: {
          minWidth: '100%',
          marginLeft: '0px',
        },
      },
    },
    loader: {
      width: '100%',
      height: '237px',
      display: 'flex',
      justifyContent: 'center',
    },
    chartName: {
      color: '#79818F',
      fontSize: '14px',
      fontWeight: 500,
      marginTop: '38px',
      position: 'relative',

      [breakpoints.up(Layout.laptop)]: {
        color: '#fff',
        fontSize: '16px',
        fontWeight: 400,
      },
    },
    currentRate: {
      display: 'flex',
      marginTop: '12px',
      marginBottom: '18px',
    },
    currentRateTitle: {
      color: '#79818F',
      fontSize: '13px',
      fontWeight: 500,
      [breakpoints.up(Layout.laptop)]: {
        color: '#fff',
        fontSize: '16px',
        fontWeight: 400,
      },
    },
    currentRateValue: {
      color: '#79818F',
      fontSize: '14px',
      fontWeight: 700,
      marginLeft: '5px',
      [breakpoints.up(Layout.laptop)]: {
        color: '#fff',
        fontSize: '16px',
        fontWeight: 700,
      },
    },
    hintButton: {
      width: '25px',
      height: '25px',
      position: 'absolute',
      top: '0px',
      right: '0px',
      marginLeft: '10px',
      cursor: 'pointer',
      background: `url(${ContectSupportIcon}) no-repeat left center`,
    },
  });
}
